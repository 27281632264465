import { Group } from "@/components/group";
import { ContactChip } from "@/components/contact-chip";

import { useAppSelector } from "@/store/app/hooks";
import { carrierReportDataSelector } from "@/store/features/carrier-report/carrierReportSelectors";

import { ReactComponent as CalendarIcon } from "@/assets/icons/calendar.svg";
import { ReactComponent as PhoneIcon } from "@/assets/icons/phone.svg";

import { Stats } from "./Stats";
import { Data } from "./Data";
import { toShortMonthDayYear } from "@/utils/formatDate";

import FMCSALogo from "@/assets/img/fmcsa.png";
import { getDateDifferenceInDays } from "@/utils/getDateDifference";

export const DBAData = () => {
  const carrierReportData = useAppSelector(carrierReportDataSelector);

  const {
    addDate,
    fax,
    emailAddress,
    phone,
    phyStreet,
    phyCity,
    phyState,
    // carrierAuth,
  } = carrierReportData || {};

  // const { brokerStatDate } = carrierAuth || {
  //   brokerStatDate: "",
  // };

  const phyAddress = `${phyStreet?.toLowerCase()}${
    phyStreet ? ", " : ""
  }${phyCity?.toLowerCase()}${phyCity ? ", " : ""}${phyState}`;

  const isLessThanAYearOld = addDate
    ? getDateDifferenceInDays(addDate, new Date().toISOString()) < 365
    : false;

  return (
    <>
      <div className="col-span-6 flex justify-end">
        <img width={62} src={FMCSALogo} alt="FMCSA" />
      </div>
      <div className="col-span-2 flex flex-col gap-2">
        <Stats
          status={isLessThanAYearOld ? "danger" : "success"}
          className="h-full"
          label="Started"
          value={toShortMonthDayYear(addDate)}
          icon={<CalendarIcon />}
          tooltip={
            isLessThanAYearOld
              ? "This broker is less than a year old in business. Please double-check to confirm."
              : ""
          }
        />
        {/* <Stats
          status={brokerStatDate ? "success" : "danger"}
          className="h-full"
          label="Authority granted"
          value={toShortMonthDayYear(brokerStatDate) || "-"}
          icon={<CalendarIcon />}
          tooltip={
            !brokerStatDate
              ? "It seems this broker does not have an active authority listed on FMCSA. Please double-check to confirm."
              : ""
          }
        /> */}
      </div>
      <Group className="col-span-4 h-full flex flex-col gap-2">
        <Data
          label="Business address"
          value={phyAddress}
          className="capitalize"
        />
        {emailAddress && (
          <Data
            label="Email domain"
            value={
              <ContactChip size="sm" color="success" className="lowercase">
                {emailAddress}
              </ContactChip>
            }
          />
        )}
        {phone && (
          <a href={`tel:${phone}`}>
            <Data
              label="Phone domain"
              value={
                <ContactChip
                  size="sm"
                  color="primary"
                  className="bg-transparent text-brand-blue-700"
                  icon={<PhoneIcon />}
                >
                  {phone}
                </ContactChip>
              }
            />
          </a>
        )}
        {fax && (
          <Data
            label="Fax"
            value={
              <ContactChip size="sm" color="primary" className="bg-transparent">
                {fax}
              </ContactChip>
            }
          />
        )}
      </Group>
    </>
  );
};
