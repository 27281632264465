import { FC } from "react";
import { StyledButton } from "@/components/styled-button";
// import { Divider } from "@/components/divider";

// import { ReactComponent as BookmarkIcon } from "@/assets/icons/bookmark-add.svg";
// import { ReactComponent as GridIcon } from "@/assets/icons/grid.svg";
// import { ReactComponent as ShareLink } from "@/assets/icons/share-link.svg";
import { ReactComponent as CloseIcon } from "@/assets/icons/close.svg";
import { useAppSelector } from "@/store/app/hooks";
import { loadDataSelector } from "@/store/features/load-info/loadInfoSelectors";
import { sendAmplitudeData } from "@/analytics";
import { AMPLITUDE_EVENTS } from "@/analytics/events";

export const Header: FC = () => {
  const loadData = useAppSelector(loadDataSelector);

  const { origin, destination } = loadData || {};

  const closeModal = () => {
    window.parent.postMessage("closeldcmodal", "*");
    sendAmplitudeData(AMPLITUDE_EVENTS.popupClosed);
  };

  return (
    <div className="flex justify-between items-center p-4 text-xl font-semibold text-text-secondary overflow-hidden">
      <h1>
        <span className="text-text-primary font-bold">{origin}</span>
        {destination && " -> "}
        <span className="text-text-primary font-bold">{destination}</span>
      </h1>
      <div className="flex gap-3">
        {/* <StyledButton isIconOnly color="primary">
          <BookmarkIcon />
        </StyledButton>
        <StyledButton isIconOnly variant="bordered">
          <GridIcon />
        </StyledButton>
        <StyledButton isIconOnly variant="bordered">
          <ShareLink />
        </StyledButton>
        <Divider /> */}
        <StyledButton isIconOnly size="sm" variant="light" onPress={closeModal}>
          <CloseIcon />
        </StyledButton>
      </div>
    </div>
  );
};
