import { DataGroup } from "./DataGroup";
import { Data } from "./Data";
import { useAppSelector } from "@/store/app/hooks";
import { carrierReportDataSelector } from "@/store/features/carrier-report/carrierReportSelectors";

import { ReactComponent as InsuranceIcon } from "@/assets/icons/certificate.svg";
import { ReactComponent as LoadingIcon } from "@/assets/icons/loading.svg";
import { getDateDifference } from "@/utils/getDateDifference";
import { toMoneyFormat } from "@/utils/toMoneyFormat";
import { toShortMonthDayYear } from "@/utils/formatDate";
import { FC } from "react";

const statuses: Record<string, string> = {
  A: "Active",
  I: "Inactive",
  N: "Not Authorized",
  "-": "-",
};

const fileStatuses: Record<string, string> = {
  Y: "Yes",
  N: "No",
  "-": "-",
};

interface Props {
  showOnly?: string[];
}

export const AuthorityStatuses: FC<Props> = ({
  showOnly = ["common", "contract", "broker", "forwarder"],
}) => {
  const carrierReportData = useAppSelector(carrierReportDataSelector);
  const { carrierAuth } = carrierReportData || {};

  const {
    bipdFile,
    cargoFile,
    bondFile,
    brokerStat,
    brokerStatDate,
    freightForwarderStat,
    freightForwarderDate,
    commonStat,
    commonStatDate,
    contractStat,
    contractStatDate,
  } = carrierAuth || {
    bipdFile: 0,
    cargoFile: "-",
    bondFile: "-",
    commonStat: "N",
    commonStatDate: "",
    brokerStat: "N",
    brokerStatDate: "",
    freightForwarderStat: "N",
    freightForwarderDate: "",
    contractStat: "N",
    contractStatDate: "",
  };

  const authorities = [
    {
      key: "common",
      name: "Common",
      status: commonStat,
      date: commonStatDate,
    },
    {
      key: "contract",
      name: "Contract",
      status: contractStat,
      date: contractStatDate,
    },
    {
      key: "broker",
      name: "Broker",
      status: brokerStat,
      date: brokerStatDate,
    },
    {
      key: "forwarder",
      name: "Forwarder",
      status: freightForwarderStat,
      date: freightForwarderDate,
    },
  ];

  const renderAuthorities = authorities.filter((authority) =>
    showOnly.includes(authority.key)
  );

  return (
    <>
      {renderAuthorities.map((authority) => (
        <DataGroup
          key={authority.key}
          header={`${authority.name} authority`}
          icon={<LoadingIcon />}
          danger={authority.status !== "A"}
          tooltip={
            authority.status !== "A"
              ? `It seems this company does not have an active ${authority.name} authority listed on FMCSA. Please double-check to confirm.`
              : ""
          }
        >
          <Data label="Status" value={statuses[authority.status] || "-"} />
          {!!authority.date && (
            <Data
              label="Since"
              value={toShortMonthDayYear(authority.date)}
              subValue={getDateDifference(authority.date)}
            />
          )}
        </DataGroup>
      ))}
      <DataGroup
        icon={<InsuranceIcon />}
        header="Insurance"
        danger={
          (!cargoFile || cargoFile === "N") &&
          (!bondFile || bondFile === "N") &&
          +bipdFile === 0
        }
      >
        <Data label="BIPD" value={toMoneyFormat(+bipdFile * 1000)} />
        <Data label="Cargo" value={fileStatuses[cargoFile] || "-"} />
        <Data label="BOND" value={fileStatuses[bondFile] || "-"} />
      </DataGroup>
    </>
  );
};
