import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ITabMenuItem, TabValue } from "@/types/common";
import { CARRIER_TABS } from "@/constants/tabs";

export interface ITabsState {
  list: ITabMenuItem[];
  activeTab: TabValue;
  isBroker: boolean;
}

const initialState: ITabsState = {
  list: CARRIER_TABS,
  activeTab: "email",
  isBroker: false,
};

export const tabsSlice = createSlice({
  name: "tabs",
  initialState,
  reducers: {
    setAvailableTabs: (state, action: PayloadAction<ITabMenuItem[]>) => {
      state.list = action.payload;
    },
    setActiveTab: (state, action: PayloadAction<TabValue>) => {
      state.activeTab = action.payload;
    },
    setIsBroker: (state, action: PayloadAction<boolean>) => {
      state.isBroker = action.payload;
    },
    resetTabsState: () => initialState,
  },
});

export const { setAvailableTabs, setActiveTab, resetTabsState, setIsBroker } =
  tabsSlice.actions;

export default tabsSlice.reducer;
