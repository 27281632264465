export enum EmailVariables {
  ORIGIN = "{{origin}}",
  DEST = "{{dest}}",
  AVAIL = "{{avail}}",
  TRUCK = "{{truck}}",
  EMAIL = "{{email}}",
  COMPANY = "{{company}}",
  RATE = "{{rate}}",
  REF = "{{ref}}",
  TRIP = "{{trip}}",
  DO = "{{do}}",
  WEIGHT = "{{weight}}",
  LENGTH = "{{length}}",
  TRIP_DO = "{{tripDO}}",
}

export enum BrokerEmailVariables {
  POSTED_LOAD_TRUCK_TYPE = "{{postedLoadTruckType}}",
  POSTED_LOAD_TRUCK_LOAD = "{{postedLoadTruckLoad}}",
  POSTED_LOAD_ORIGIN_DESTINATION = "{{postedLoadOriginDestination}}",
  POSTED_LOAD_ORIGIN = "{{postedLoadOrigin}}",
  POSTED_LOAD_DESTINATION = "{{postedLoadDestination}}",
  POSTED_LOAD_AVAILABILITY_DATE = "{{postedLoadAvailabilityDate}}",
}
