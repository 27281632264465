import { useAppSelector } from "@/store/app/hooks";

import {
  carrierReportDataSelector,
  isCarrierReportLockSelector,
} from "@/store/features/carrier-report/carrierReportSelectors";

import { ReactComponent as CrossIcon } from "@/assets/icons/close.svg";
import { ReactComponent as CheckIcon } from "@/assets/icons/check.svg";

import { cn } from "@nextui-org/theme";
import { Button, Link } from "@nextui-org/react";
import { sendAmplitudeData } from "@/analytics";
import { AMPLITUDE_EVENTS } from "@/analytics/events";
import { FC } from "react";

interface Props {
  withoutFactoring?: boolean;
}

export const Heading: FC<Props> = ({ withoutFactoring }) => {
  const carrierReportData = useAppSelector(carrierReportDataSelector);
  const isCarrierReportLock = useAppSelector(isCarrierReportLockSelector);

  const { dbaName, legalName, docketNumbers, factoringInfo, dotNumber } =
    carrierReportData || {};

  const { eligibleForPurchase } = factoringInfo || {};

  const icon = eligibleForPurchase ? <CheckIcon /> : <CrossIcon />;

  const checkClassName = cn(
    "h-6 w-6 rounded-full flex items-center justify-center text-white",
    {
      "bg-success-500": eligibleForPurchase,
      "bg-danger-500": !eligibleForPurchase,
      "bg-gray-500": !factoringInfo || eligibleForPurchase === 2,
    }
  );

  const chipClassName = cn(
    "w-fit h-9 flex gap-2 items-center p-1 px-2 rounded-md flex-shrink-0",
    {
      "bg-success-50": eligibleForPurchase,
      "bg-danger-50": !eligibleForPurchase,
      "bg-gray-50": !factoringInfo || eligibleForPurchase === 2,
    }
  );

  const approvedText = factoringInfo
    ? eligibleForPurchase === 2
      ? "No Data"
      : eligibleForPurchase
      ? "Approved to factor"
      : "Not approved to factor"
    : "No Data";

  const companyDataClassName = cn(
    "flex",
    withoutFactoring
      ? "flex-row flex-wrap items-baseline gap-2"
      : "flex-col gap-1"
  );

  const companyNameClassName = cn(
    "text-xl font-semibold ",
    withoutFactoring ? "flex-shrink-0" : "max-w-80"
  );

  const handleFactoringClick = () => {
    sendAmplitudeData(AMPLITUDE_EVENTS.popupFactoringECapitalButtonClicked);
  };

  return (
    <div className="col-span-6 flex justify-between">
      <div className={companyDataClassName}>
        <h2 className={companyNameClassName}>{dbaName || legalName || ""}</h2>
        <p className="text-gray-500 text-sm">
          {docketNumbers?.join(", ")}
          {dotNumber && " | DOT " + dotNumber}
        </p>
      </div>
      {!withoutFactoring && (
        <div className="flex gap-2">
          <div className={chipClassName}>
            <div className={checkClassName}>{icon}</div>
            <p className="flex flex-col text-sm text-gray-800">
              {approvedText}
            </p>
          </div>
          {!isCarrierReportLock && (
            <Button
              as={Link}
              href="https://try.loadconnect.io/eCapitalDigital"
              target="_blank"
              className="flex-shrink-0 h-9 gap-1 bg-[#E9953E] text-white"
              radius="sm"
              onPress={handleFactoringClick}
            >
              Factor with eCapital {"->"}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
