import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  IAccount,
  IEmailData,
  IEmailDataSearch,
  ITemplate,
} from "@/types/common";
import {
  getAccountsAsync,
  getTemplatesAsync,
  sendEmailAsync,
} from "./emailAsyncThunks";
import { Key } from "react";
import { EmailSendStatus } from "@/constants/email-send-status";

export interface IEmailState {
  loading: boolean;
  activeTemplateId?: number;
  activeTemplateData?: ITemplate;
  accounts: IAccount[];
  templates?: ITemplate[];
  senderEmailKey: Key | null;
  recieverEmail?: string;
  emailData: IEmailData | null;
  emailStatus: EmailSendStatus;
  search: IEmailDataSearch | null;
}

const initialState: IEmailState = {
  loading: false,
  activeTemplateId: undefined,
  activeTemplateData: {
    id: -1,
    name: "",
    subject: "",
    template: "",
    cc: "",
    labels: [],
  },
  accounts: [],
  templates: [],
  senderEmailKey: "",
  recieverEmail: "",
  emailData: null,
  emailStatus: EmailSendStatus.NONE,
  search: null,
};

export const emailSlice = createSlice({
  name: "email",
  initialState,
  reducers: {
    setActiveTemplate: (state, action: PayloadAction<string | number>) => {
      const payloadId =
        typeof action.payload === "string"
          ? parseInt(action.payload)
          : action.payload;
      state.activeTemplateId = payloadId;
      state.activeTemplateData = state.templates?.find(
        (template) => template.id === payloadId
      );
    },
    setActiveTemplateData: (
      state,
      action: PayloadAction<Partial<ITemplate>>
    ) => {
      if (state.activeTemplateData)
        state.activeTemplateData = {
          ...state.activeTemplateData,
          ...action.payload,
        };
    },
    setEmailData: (state, action: PayloadAction<IEmailData>) => {
      state.emailData = action.payload;
    },
    setSenderEmailKey: (state, action: PayloadAction<Key | null>) => {
      state.senderEmailKey = action.payload;
    },
    setRecieverEmail: (state, action: PayloadAction<string | undefined>) => {
      state.recieverEmail = action.payload;
    },
    setEmailStatus: (state, action: PayloadAction<EmailSendStatus>) => {
      state.emailStatus = action.payload;
    },
    setSearch: (state, action: PayloadAction<IEmailDataSearch | null>) => {
      state.search = action.payload;
    },
    resetEmailState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getTemplatesAsync.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTemplatesAsync.fulfilled, (state, action) => {
      state.templates = action.payload;
      state.loading = false;
    });
    builder.addCase(getTemplatesAsync.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getAccountsAsync.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAccountsAsync.fulfilled, (state, action) => {
      if (Array.isArray(action.payload)) {
        state.accounts = action.payload;
      } else {
        state.accounts = [action.payload];
      }
      state.loading = false;
    });
    builder.addCase(getAccountsAsync.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(sendEmailAsync.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(sendEmailAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.emailStatus = action.payload;
    });
    builder.addCase(sendEmailAsync.rejected, (state) => {
      state.loading = false;
      state.emailStatus = EmailSendStatus.ERROR;
    });
  },
});

export const {
  setActiveTemplate,
  setActiveTemplateData,
  setEmailData,
  setSenderEmailKey,
  setRecieverEmail,
  resetEmailState,
  setEmailStatus,
  setSearch,
} = emailSlice.actions;

export default emailSlice.reducer;
