import { FC, useEffect } from "react";
import { Variable } from "./Variable";
import { useAppSelector } from "@/store/app/hooks";
import {
  activeTemplateSelector,
  emailDataSelector,
} from "@/store/features/email/emailSelectors";
import { AMPLITUDE_EVENTS } from "@/analytics/events";
import { sendAmplitudeData } from "@/analytics";
import { useQuill } from "react-quilljs";
import { IEmailDataItem } from "@/types/common";
import "quill/dist/quill.snow.css";
import "./index.css";

interface IProps {
  value?: string;
  onChange?: any;
}

export const TextEditorQuill: FC<IProps> = ({ value, onChange }) => {
  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"],
      [{ align: [] }],

      [{ list: "ordered" }, { list: "bullet" }],
    ],
  };

  const formats = [
    "bold",
    "italic",
    "underline",
    "strike",
    "align",
    "list",
    "indent",
    "size",
    "header",
    "link",
    "image",
    "video",
    "color",
    "background",
  ];
  const { quill, quillRef } = useQuill({
    modules,
    formats,
  });
  const emailData = useAppSelector(emailDataSelector);
  const activeTemplateId = useAppSelector(activeTemplateSelector);

  const handleClick = (e: string) => {
    if (quill) {
      quill.focus();
      const cursorPosition = quill.getSelection()?.index || 0;
      quill.insertText(cursorPosition, e);
    }
    sendAmplitudeData(AMPLITUDE_EVENTS.popupVariablesClicked);
  };

  useEffect(() => {
    if (quill) quill.clipboard.dangerouslyPasteHTML(value || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTemplateId, quill]);

  useEffect(() => {
    if (quill) {
      quill.on("text-change", (delta, oldDelta, source) => {
        onChange(quill.root.innerHTML);
      });
    }
  }, [onChange, quill]);

  return (
    <div className="max-w-full h-full flex flex-col">
      <div className="text-editor">
        <div
          ref={quillRef}
          className="w-full h-full overflow-auto flex flex-col border-0 border-b-1 border-gray-200"
        />
      </div>
      {value && (
        <div className="p-3 flex flex-col gap-2">
          <div className="flex flex-wrap gap-1">
            {emailData &&
              Object.keys(emailData).map(
                (key) =>
                  !!emailData[key as IEmailDataItem] && (
                    <Variable
                      key={key}
                      label={key}
                      onClick={() =>
                        handleClick(
                          emailData[key as IEmailDataItem]?.toString() || " "
                        )
                      }
                    />
                  )
              )}
          </div>
        </div>
      )}
    </div>
  );
};
