export const toMoneyFormat = (value?: number) => {
  if (!value) return "$0.00";
  return value.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
};

export const toSplitNumber = (value?: number) => {
  if (!value) return "0";

  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
