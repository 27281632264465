import { Group } from "@/components/group";
import { ProgressCircle } from "./ProgressCircle";
import { FC, useMemo } from "react";
import { ISmsData } from "@/types/carrierReport";
import { IconTooltip } from "../icon-tooltip";

interface IProps {
  data?: ISmsData;
}

const TOOLTIP_TEXT =
  "The percentile scores provide context for the BASIC measurements of a carrier by comparing their performance to other active carriers, assigning a percentile from 0 to 100 (the higher the percentile, the worse the safety performance). These scores are based on data from roadside inspections and crash reports and may differ slightly from other carrier databases, including percentiles assigned by the FMCSA that are not publicly available. The percentiles assigned by LoadConnect are not an endorsement of a carrier’s safety status but are intended to provide context for the BASIC measures from SMS. Readers are encouraged to review Sections 5221 and 5223 of the FAST Act regarding the FMCSA’s CSA program and the use of this data.";

export const Inspections: FC<IProps> = ({ data }) => {
  const { unsafeDrivPct, hosDrivPct, drivFitPct, vehMaintPct } = data || {};

  const inspectionsData = useMemo(
    () => [
      { name: "Unsafe driving", percent: unsafeDrivPct || 0, limit: 65 },
      { name: "HOS compliance", percent: hosDrivPct || 0, limit: 65 },
      { name: "Driver fitness", percent: drivFitPct || 0, limit: 80 },
      { name: "Vehicle maintenance", percent: vehMaintPct || 0, limit: 80 },
    ],
    [drivFitPct, hosDrivPct, unsafeDrivPct, vehMaintPct]
  );

  if (!data) return null;

  return (
    <Group className="shrink-0">
      <div className="flex w-full justify-between mb-2">
        <h3 className="text-sm w-full">BASICs percentile</h3>
        <span className="w-full text-xs flex justify-end items-center gap-1 mb-1">
          <span className="h-[13px]">Source: SMS</span>
          <IconTooltip className="max-w-96 text-xs" tooltip={TOOLTIP_TEXT} />
        </span>
      </div>
      <div className="grid grid-cols-4 justify-items-center">
        {inspectionsData?.map((inspection, index) => (
          <ProgressCircle
            key={index}
            label={inspection.name}
            percent={inspection.percent}
            limit={inspection.limit}
          />
        ))}
      </div>
    </Group>
  );
};
