import { TRootState } from "@/store/app/store";
import {
  resetAccountState,
  setAccountData,
  setTokens,
} from "@/store/features/account/accountSlice";
import {
  resetCarrierReportState,
  setCarrierReportAvailability,
  setCarrierReportData,
  setCarrierReportLock,
} from "@/store/features/carrier-report/carrierReportSlice";
import {
  resetEmailState,
  setActiveTemplate,
  setEmailData,
  setRecieverEmail,
  setSearch,
} from "@/store/features/email/emailSlice";
import {
  resetLoadInfoState,
  setLoadData,
  setSource,
} from "@/store/features/load-info/loadInfoSlice";
import {
  resetTabsState,
  setActiveTab,
  setAvailableTabs,
  setIsBroker,
} from "@/store/features/tabs/tabsSlice";
import {
  resetTripInfoState,
  setActiveUnitSystem,
  setIsUnitsSwitchAccessible,
} from "@/store/features/trip-info/tripInfoSlice";
import { ThunkDispatch } from "@reduxjs/toolkit";

import { IMessage, ITokens } from "@/types/common";
import {
  getAccountsAsync,
  getTemplatesAsync,
} from "@/store/features/email/emailAsyncThunks";
import { getUserInfoAsync } from "@/store/features/account/accountAsyncThunks";
import { getCarrierReportAsync } from "@/store/features/carrier-report/carrierReportAsyncThunks";
import { dummyFactoringData } from "@/constants/dummy-factoring-data";
import { BROKER_TABS, CARRIER_TABS } from "@/constants/tabs";

type ResetStoreFunc = (
  dispatch: ThunkDispatch<TRootState, any, any>,
  config: {
    isLoadLink: boolean;
    tokens: ITokens;
    data: IMessage;
  }
) => void;

export const resetStore: ResetStoreFunc = async (
  dispatch,
  { isLoadLink, tokens, data }
) => {
  dispatch(resetAccountState());
  dispatch(resetLoadInfoState());
  dispatch(resetTabsState());
  dispatch(resetCarrierReportState());
  dispatch(resetTripInfoState());
  dispatch(resetEmailState());

  dispatch(
    setCarrierReportAvailability(data.data.hasOwnProperty("factoringData"))
  );

  if (!data.hasOwnProperty("isFactoringAvailable")) {
    dispatch(setCarrierReportLock(false));
  } else {
    dispatch(setCarrierReportLock(!data.isFactoringAvailable));
  }

  if (
    data.hasOwnProperty("isFactoringAvailable") &&
    !data.isFactoringAvailable
  ) {
    dispatch(setCarrierReportData(dummyFactoringData));
  } else if (data.data.mc) {
    dispatch(
      getCarrierReportAsync({
        mc: data.data.mc,
        tokens,
      })
    );
  }

  if (data.data.email_data.email) {
    dispatch(setRecieverEmail(data.data.email_data.email));
  }

  if (data.data.search) {
    dispatch(setSearch(data.data.search));
  }

  if (data.hasOwnProperty("isBroker")) {
    dispatch(setIsBroker(data.isBroker));
    dispatch(setAvailableTabs(data.isBroker ? BROKER_TABS : CARRIER_TABS));
  }

  if (data.hasOwnProperty("isBroker")) {
    dispatch(
      setActiveTab(data.data.email_data.email ? "email" : "carrierReport")
    );
  } else if (data.tab) {
    dispatch(setActiveTab(data.tab));
  } else if (data.data.email_data.email) {
    dispatch(setActiveTab("email"));
  } else {
    dispatch(setActiveTab("loadInfo"));
  }

  dispatch(setTokens(tokens));
  await Promise.all([
    dispatch(getAccountsAsync(tokens)),
    dispatch(getUserInfoAsync(tokens)),
    dispatch(getTemplatesAsync(tokens)),
  ]);
  dispatch(setLoadData(data.data));
  dispatch(setSource(data?.source?.url || ""));
  dispatch(setAccountData(data.activeAccount));
  dispatch(setEmailData(data.data.email_data));
  dispatch(setActiveTemplate(data.activeTemplateId));

  dispatch(setIsUnitsSwitchAccessible(isLoadLink));
  dispatch(setActiveUnitSystem("imperial"));
};
