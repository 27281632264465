import { FC, useMemo } from "react";
import * as d3 from "d3";
import { Group } from "@/components/group";
import { MapLegendItem } from "./MapLegendItem";
import geoData from "@/assets/data/usa-geo.json";
import { Tooltip } from "@nextui-org/react";
import { IInspectionStateCount } from "@/types/carrierReport";

const MAP_WIDTH = 268;
const MAP_HEIGHT = 185;

interface IProps {
  data: IInspectionStateCount[];
  total?: number;
}

export const ChoroplethMap: FC<IProps> = ({ data, total }) => {
  const projection = d3
    .geoAlbersUsa()
    .translate([MAP_WIDTH / 2, MAP_HEIGHT / 2])
    .scale(370);

  const geoPathGenerator = d3.geoPath().projection(projection);

  const allSvgPaths = useMemo(
    () =>
      geoData.features.map((shape: any) => {
        const region = data.find(
          (region) => region.reportState === shape.properties.id
        );
        const value = region?.count || 0;

        const color = value
          ? value > 9
            ? "#348EFD"
            : value > 5
            ? "#5AAFFF"
            : value > 3
            ? "#8FCDFF"
            : "#BDE0FF"
          : "#DAEDFF";

        const render = (
          <path
            key={shape.properties.id}
            d={geoPathGenerator(shape) as string}
            stroke="white"
            strokeWidth={0.5}
            fill={color}
            fillOpacity={1}
          />
        );

        if (value === 0) {
          return render;
        }

        return (
          <Tooltip
            key={shape.properties.id}
            content={`${shape.properties.name} - ${value}`}
          >
            {render}
          </Tooltip>
        );
      }),
    [data, geoPathGenerator]
  );

  return (
    <Group className="flex flex-col gap-1 items-center">
      <div className="w-full flex justify-between items-center gap-4">
        <p className="text-sm text-gray-500">Total Inspections</p>
        <p className="text-sm text-gray-950 font-medium">{total || 0}</p>
      </div>
      <div className="flex gap-2 items-center">
        <svg
          width={MAP_WIDTH}
          height={MAP_HEIGHT}
          className="scale-[0.63] md:scale-100"
        >
          {allSvgPaths}
        </svg>
        <div className="flex flex-col justify-center items-center text-gray-600 text-sm gap-x-5 gap-y-3 flex-wrap mt-5">
          <MapLegendItem value="10+" color="#348EFD" />
          <MapLegendItem value="6-9" color="#5AAFFF" />
          <MapLegendItem value="3-6" color="#8FCDFF" />
          <MapLegendItem value="0-3" color="#BDE0FF" />
          <MapLegendItem value="N/A" color="#DAEDFF" />
        </div>
      </div>
    </Group>
  );
};
