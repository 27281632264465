export const ExpandLoadView = () => {
  return (
    <div className="w-full h-[300px] flex flex-col gap-2 items-center justify-center col-span-6">
      <h2 className="text-xl font-semibold">Not found</h2>
      <p className="text-sm">
        We're sorry but there is no data available for this company
      </p>
    </div>
  );
};
