import { cn } from "@nextui-org/theme";
import { FC, ReactNode } from "react";

interface IProps {
  label?: string;
  value?: ReactNode;
  subValue?: string;
  className?: string;
  vertical?: boolean;
}

export const Data: FC<IProps> = ({
  label,
  value,
  subValue,
  className,
  vertical,
}) => {
  const wrapperClassName = cn(
    "flex",
    vertical ? "flex-col gap-1" : "flex-row justify-between gap-2"
  );

  const valueClassName = cn(
    "text-gray-950",
    "text-sm",
    "font-medium",
    vertical ? "text-start" : "text-end",
    className
  );

  const valueWrapperClassName = cn(
    "flex flex-col shrink-0",
    vertical ? "items-start" : "items-end"
  );

  return (
    <div className={wrapperClassName}>
      <div className="w-full flex justify-between items-center">
        <span className="text-gray-500 text-sm">{label}</span>
      </div>
      <div className={valueWrapperClassName}>
        <span className={valueClassName}>{value}</span>
        <span className="text-gray-600 text-sm text-end">{subValue}</span>
      </div>
    </div>
  );
};
