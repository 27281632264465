import { useAppSelector } from "@/store/app/hooks";
import {
  carrierReportDataSelector,
  carrierReportLoadingSelector,
  isCarrierReportAvailableSelector,
} from "@/store/features/carrier-report/carrierReportSelectors";
import { InDevelopmentView } from "@/components/in-development-view";
import { Loader } from "@/components/loader";

import { useMemo } from "react";
import { ExpandLoadView } from "../expand-load-view";
import { isBrokerSelector } from "@/store/features/tabs/tabsSelectors";
import { BrokerView } from "./BrokerView";
import { CarrierView } from "./CarrierView";

export const CarrierReportView = () => {
  const carrierReportData = useAppSelector(carrierReportDataSelector);
  const isCarrierReportAvaiable = useAppSelector(
    isCarrierReportAvailableSelector
  );
  const loading = useAppSelector(carrierReportLoadingSelector);
  const isBroker = useAppSelector(isBrokerSelector);

  const render = useMemo(() => {
    if (loading) {
      return <Loader show={loading} />;
    }
    if (!isCarrierReportAvaiable) {
      return <InDevelopmentView />;
    }
    if (!carrierReportData) {
      return <ExpandLoadView />;
    }
    if (isBroker) {
      return <BrokerView />;
    }
    return <CarrierView />;
  }, [carrierReportData, isBroker, isCarrierReportAvaiable, loading]);

  return <div className="grid grid-cols-6 gap-2 items-start">{render}</div>;
};
