import { ITabMenuItem } from "@/types/common";

export const CARRIER_TABS: ITabMenuItem[] = [
  { label: "Email", value: "email" },
  { label: "Load info", value: "loadInfo" },
  { label: "Broker report", value: "carrierReport" },
];

export const BROKER_TABS: ITabMenuItem[] = [
  { label: "Email", value: "email" },
  { label: "Carrier report", value: "carrierReport" },
];
