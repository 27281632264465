import { FC, useEffect, useState } from "react";
import { Layout } from "@/components/layout";
import { Header } from "@/components/header";
import { MapView } from "@/components/map-view";
import { CalculatorView } from "@/components/calculator-view";
import { MainView } from "@/components/main-view";
import { Brand } from "@/components/brand";
import { useAppDispatch, useAppSelector } from "@/store/app/hooks";
import { ILoadDataV2, IMessage, ITokens } from "@/types/common";
import { setAmplitudeUserDevice } from "./analytics";
import { accessTokenSelector } from "./store/features/account/accountSelectors";
import { isBrokerSelector } from "./store/features/tabs/tabsSelectors";
import { resetStore } from "./utils/resetStore";
import { isEqual } from "lodash";
import { EquipmentDetails } from "./components/load-info-view/EquipmentDetails";
import { CompanyDetails } from "./components/load-info-view/CompanyDetails";
import { Map } from "./components/map-view/Map";

const App: FC = () => {
  const [prevData, setPrevData] = useState<ILoadDataV2 | null>(null);
  const dispatch = useAppDispatch();
  const currentAccess = useAppSelector(accessTokenSelector);
  const isBroker = useAppSelector(isBrokerSelector);

  useEffect(() => {
    const receiveMessage = async ({ data }: { data: IMessage | string }) => {
      if (typeof data === "object" && data?.data?.origin) {
        console.log("Message received: ", data.source);
        if (isEqual(prevData, data.data)) return;
        setPrevData(data.data);
        const isLoadLink = data.source.url.includes("loadlink");
        const tokens: ITokens = {
          accessToken: data.accessToken,
          refreshToken: data.refreshToken,
        };

        setAmplitudeUserDevice(data.deviceId);

        await resetStore(dispatch, {
          isLoadLink,
          tokens,
          data,
        });
      }
    };

    window.addEventListener("message", receiveMessage, false);
    return () => window.removeEventListener("message", receiveMessage, false);
  }, [currentAccess, dispatch, prevData]);

  return (
    <Layout>
      <Header />
      {isBroker ? (
        <div className="flex flex-col gap-3 p-3 border-r-1 border-gray-200">
          <div className="h-80">
            <Map />
          </div>
          <CompanyDetails />
          <EquipmentDetails />
        </div>
      ) : (
        <>
          <MapView />
          <CalculatorView />
          <Brand />
        </>
      )}
      <MainView />
    </Layout>
  );
};

export default App;
