import { FC } from "react";
import { CircularProgress } from "@nextui-org/react";

interface IProps {
  label: string;
  percent: number;
  limit: number;
}

const COLORS = {
  success: "#17B26A",
  danger: "#F04438",
  warning: "#FDB022",
  trail: "#E9EAEB",
};

export const ProgressCircle: FC<IProps> = ({ label, percent, limit }) => {
  const color =
    percent >= limit
      ? COLORS.danger
      : percent >= limit - 10
      ? COLORS.warning
      : COLORS.success;

  return (
    <>
      <div className="hidden stroke-[#17B26A]" />
      <div className="hidden stroke-[#F04438]" />
      <div className="hidden stroke-[#FDB022]" />
      <div className="hidden stroke-[#E9EAEB]" />
      <CircularProgress
        label={<ProgressCircleLabel label={label} limit={limit} percent={0} />}
        aria-label={label}
        color="primary"
        showValueLabel={true}
        value={percent}
        classNames={{
          svg: "w-16 h-16",
          value: "text-sm",
          indicator: `stroke-[${color}]`,
        }}
      />
    </>
  );
};

export const ProgressCircleLabel: FC<Partial<IProps>> = ({ label }) => (
  <span className="text-sm">{label}</span>
);
