import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAccounts, getTemplates, sendEmailV3 } from "@/modules/server";
import { IEmailDataSearch, ITokens } from "@/types/common";

export const getAccountsAsync = createAsyncThunk(
  "email/getAccounts",
  async (tokens: ITokens) => {
    const response = await getAccounts(tokens.accessToken, tokens.refreshToken);
    return response;
  }
);

export const getTemplatesAsync = createAsyncThunk(
  "email/getTemplates",
  async (tokens: ITokens) => {
    const response = await getTemplates(
      tokens.accessToken,
      tokens.refreshToken
    );
    return response;
  }
);

interface sendEmailParams {
  tokens: ITokens;
  toEmail: string;
  subject: string;
  template: string;
  account: string;
  cc: string;
  isBroker: boolean;
  search: IEmailDataSearch | null;
}

export const sendEmailAsync = createAsyncThunk(
  "email/sendEmail",
  async (data: sendEmailParams) => {
    const response = await sendEmailV3(
      data.toEmail,
      data.subject,
      data.template,
      data.account || "",
      data.tokens.accessToken,
      data.tokens.refreshToken,
      data.cc,
      data.isBroker,
      data.search
    );

    return response;
  }
);
