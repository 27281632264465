import { useAppSelector } from "@/store/app/hooks";
import { DataGroup } from "./DataGroup";
import { toMoneyFormat } from "@/utils/toMoneyFormat";
import { cn } from "@nextui-org/theme";
import ECapitalLogo from "@/assets/img/ecapital.svg";

const riskLevelColorMap: Record<string, string> = {
  low: "success",
  "low-medium": "success",
  medium: "warning",
  "medium-high": "danger",
  high: "danger",
  "-": "gray",
};

const creditScoreColorMap: Record<string, string> = {
  A: "success",
  B: "success",
  C: "warning",
  D: "warning",
  E: "danger",
  F: "danger",
  "-": "gray",
};

export const ECapitalData = () => {
  const factoringInfo = useAppSelector(
    (state) => state.carrierReport.data?.factoringInfo
  );

  const {
    creditLimitTotal,
    availableCredit,
    creditScore,
    riskLevel,
    last60AverageDaysToPay,
    last90AverageDaysToPay,
    allTimeAverageDaysToPay,
    eligibleForPurchase,
  } = factoringInfo || {};

  const riskLevelClassName = cn("text-sm", {
    [`text-${riskLevelColorMap[riskLevel?.toLowerCase() || "-"]}`]: riskLevel,
  });

  const creditScoreClassName = cn("text-sm", {
    [`text-${creditScoreColorMap[creditScore || "-"]}`]: creditScore,
  });

  if (!factoringInfo) return null;

  const groupClassName = cn("col-span-6", {
    "bg-success-50":
      eligibleForPurchase &&
      (creditScore === "A" || creditScore === "B") &&
      (riskLevel === "Low" || riskLevel === "Low-Medium"),
    "bg-warning-50":
      creditScore === "C" || creditScore === "D" || riskLevel === "Medium",
    "bg-error-50":
      !eligibleForPurchase ||
      creditScore === "E" ||
      creditScore === "F" ||
      riskLevel === "High" ||
      riskLevel === "Medium-High",
  });

  return (
    <DataGroup
      outerClassName={groupClassName}
      innerClassName="grid grid-cols-2 gap-5"
    >
      <div className="flex justify-between items-center col-span-3">
        <h4 className="text-sm  text-gray-950">
          Eligibility for invoice purchased
        </h4>
        <img width={100} src={ECapitalLogo} alt="ECapital" />
      </div>
      <div className="grid grid-cols-2 gap-3 gap-y-5">
        <div className="flex flex-col">
          <h5 className="text-sm text-gray-500">Credit limit</h5>
          <p className="text-sm text-gray-950">
            {toMoneyFormat(creditLimitTotal)}
          </p>
        </div>
        <div className="flex flex-col">
          <h5 className="text-sm text-gray-500">Available credit</h5>
          <p className="text-sm text-gray-950">
            {toMoneyFormat(availableCredit)}
          </p>
        </div>
        <div className="flex flex-col">
          <h5 className="text-sm text-gray-500">Risk level</h5>
          <p className={riskLevelClassName}>{riskLevel}</p>
        </div>
        <div className="flex flex-col">
          <h5 className="text-sm text-gray-500">Credit score</h5>
          <p className={creditScoreClassName}>{creditScore}</p>
        </div>
      </div>
      <div className="flex flex-col gap-1">
        <h5 className="text-sm text-gray-500 mb-1">Average days to pay</h5>
        <div className="w-full flex justify-between items-center">
          <p className="text-xs text-gray-950">last 60 days</p>
          <p className="text-sm text-gray-950">{last60AverageDaysToPay}</p>
        </div>
        <div className="w-full flex justify-between items-center">
          <p className="text-xs text-gray-950">last 90 days</p>
          <p className="text-sm text-gray-950">{last90AverageDaysToPay}</p>
        </div>
        <div className="w-full flex justify-between items-center">
          <p className="text-xs text-gray-950">all</p>
          <p className="text-sm text-gray-950">{allTimeAverageDaysToPay}</p>
        </div>
      </div>
    </DataGroup>
  );
};
