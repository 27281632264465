import { Tooltip } from "@nextui-org/react";
import { FC } from "react";
import { ReactComponent as InfoIcon } from "@/assets/icons/info.svg";

interface Props {
  tooltip?: string;
  className?: string;
}

export const IconTooltip: FC<Props> = ({ tooltip, className }) => {
  const cn = `max-w-44 ${className}`;

  return (
    <Tooltip content={tooltip} size="md" className={cn}>
      <InfoIcon />
    </Tooltip>
  );
};
