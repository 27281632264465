import { FC, ReactNode } from "react";
import { Loader } from "../loader";
import { useAppSelector } from "@/store/app/hooks";
import { loadingEmailSelector } from "@/store/features/email/emailSelectors";
import { isBrokerSelector } from "@/store/features/tabs/tabsSelectors";
import "./style.css";

interface IProps {
  children: ReactNode | ReactNode[];
}

const layouts = {
  broker: "broker-layout-grid",
  default: "layout-grid",
};

export const Layout: FC<IProps> = ({ children }) => {
  const loading = useAppSelector(loadingEmailSelector);
  const isBroker = useAppSelector(isBrokerSelector);

  return (
    <>
      <div className={isBroker ? layouts.broker : layouts.default}>
        {children}
      </div>
      <Loader show={loading} />
    </>
  );
};
