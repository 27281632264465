import { useAppSelector } from "@/store/app/hooks";
import {
  carrierReportDataSelector,
  isCarrierReportLockSelector,
} from "@/store/features/carrier-report/carrierReportSelectors";

import { AuthorityStatuses } from "./AuthorityStatuses";
import { DBAData } from "./DBAData";
import { Heading } from "./Heading";
import { ECapitalData } from "./ECapitalData";
import { Button, Link } from "@nextui-org/react";

import { ReactComponent as WarningIcon } from "@/assets/icons/warning.svg";
import { LockedView } from "./LockedView";

export const CarrierView = () => {
  const carrierReportData = useAppSelector(carrierReportDataSelector);
  const isCarrierReportLock = useAppSelector(isCarrierReportLockSelector);

  const href = `https://loadconnect.io/trucking/${carrierReportData?.dotNumber}`;

  return (
    <>
      <Heading />
      {isCarrierReportLock && <LockedView />}
      <DBAData />
      <div className="grid grid-cols-3 col-span-6 gap-2">
        <AuthorityStatuses showOnly={["broker", "forwarder"]} />
      </div>
      {!isCarrierReportLock && <ECapitalData />}
      <div className="w-full col-span-6 flex gap-4 items-center px-4 py-3 rounded-xl bg-brand-blue-50 text-brand-blue-700 text-sm">
        <WarningIcon className="w-6 h-6" />
        <div>
          <p className="font-medium">Disclaimer</p>
          <p className="text-gray-800 font-normal">
            Please note: past performance doesn’t guarantee future payment 
          </p>
        </div>
      </div>
      {!!carrierReportData?.dotNumber && (
        <div className="col-span-6 w-full flex justify-end mt-2">
          <Button
            as={Link}
            href={href}
            target="_blank"
            variant="bordered"
            color="default"
            className="self-end"
          >
            See the full page
          </Button>
        </div>
      )}
    </>
  );
};
