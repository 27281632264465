import { AuthorityStatuses } from "./AuthorityStatuses";
import { Group } from "@/components/group";

import { useAppSelector } from "@/store/app/hooks";
import { carrierReportDataSelector } from "@/store/features/carrier-report/carrierReportSelectors";

import { ReactComponent as CalendarIcon } from "@/assets/icons/calendar.svg";
import { ReactComponent as TruckIcon } from "@/assets/icons/truck.svg";

import { Stats } from "./Stats";
import { Data } from "./Data";
import { toShortMonthDayYear } from "@/utils/formatDate";

import { getDateDifferenceInDays } from "@/utils/getDateDifference";
import { Inspections } from "./Inspections";
import { ChoroplethMap } from "./ChoroplethMap";
import { Heading } from "./Heading";
import { toSplitNumber } from "@/utils/toMoneyFormat";
import FMCSALogo from "@/assets/img/fmcsa.png";

export const BrokerView = () => {
  const carrierReportData = useAppSelector(carrierReportDataSelector);

  const {
    addDate,
    phyStreet,
    phyCity,
    phyState,
    powerUnits,
    mcs150Mileage,
    mcs150MileageYear,
    inspectionsStateCount,
    smsData,
    // carrierAuth,
  } = carrierReportData || {};

  const phyAddress = `${phyStreet?.toLowerCase()}${
    phyStreet ? ", " : ""
  }${phyCity?.toLowerCase()}${phyCity ? ", " : ""}${phyState}`;

  const isLessThanAYearOld = addDate
    ? getDateDifferenceInDays(addDate, new Date().toISOString()) < 365
    : false;

  return (
    <>
      <div className="col-span-6 flex justify-between items-end gap-4">
        <Heading withoutFactoring />
        <span className="flex gap-1 text-xs items-center">
          Source: <img width={62} src={FMCSALogo} alt="FMCSA" />
        </span>
      </div>
      <div className="col-span-3 grid grid-cols-2 gap-2">
        <Stats
          status={isLessThanAYearOld ? "danger" : "success"}
          label="Started"
          className="h-full"
          value={toShortMonthDayYear(addDate)}
          icon={<CalendarIcon />}
          tooltip={
            isLessThanAYearOld
              ? "This broker is less than a year old in business. Please double-check to confirm."
              : ""
          }
        />
        <Stats
          status="success"
          label="Trucks"
          className="h-full"
          value={powerUnits}
          icon={<TruckIcon />}
        />
        <Group className="col-span-2 grid grid-cols-2 gap-2">
          <Data
            label={`${mcs150MileageYear} Mileage`}
            value={toSplitNumber(mcs150Mileage)}
            className="capitalize"
            vertical
          />
          <Data
            label="Business address"
            value={phyAddress}
            className="capitalize"
            vertical
          />
        </Group>
        <div className="col-span-2">
          <ChoroplethMap
            total={smsData?.inspTotal}
            data={inspectionsStateCount || []}
          />
        </div>
      </div>
      <div className="col-span-3 flex flex-col gap-2">
        <AuthorityStatuses showOnly={["common", "contract"]} />
      </div>
      <div className="col-span-6">
        <Inspections data={smsData} />
      </div>
    </>
  );
};
